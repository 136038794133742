export const showDocument = (country) => {
  switch (country) {
    case 'BR': {
      return 'CPF';
    }
    case 'CL': {
      return 'RUT';
    }
    case 'PE': {
      return 'DNI';
    }
    default: {
      return '';
    }
  }
};

export const initBankList = (selectMethod, country) => {
  if (selectMethod && selectMethod.child && selectMethod.child.length > 0) {
    const methodSubItem = selectMethod.child.find(item => item.country === country);
    return methodSubItem ? methodSubItem?.nameCode || [] : [];
  }
  return [];
};

export const initCanUseMethod = (methodList, country) => {
  const array = [];
  if (methodList && methodList.length > 0) {
    methodList.forEach(item => {
      if (item.supportCountry && (item.supportCountry.includes('ALL') || item.supportCountry.includes(country))) {
        array.push(item);
      }
    });
  }
  return array;
};

// 设置requiredFields
export const initRequiredFields = (_this, selectMethod) => {
  if (selectMethod.requiredFields && selectMethod.requiredFields.length) {
    _this.displayItems = {};
    _this.formRules = { ..._this.formSaveRules };
    selectMethod.requiredFields.forEach(param => {
      if ('document' === param) {
        _this.displayItems.document = 'document';
      } else if ('streetNumber' === param) {
        _this.displayItems.streetNumber = 'streetNumber';
      }
      _this.formRules[param] = [
        {
          required: true,
          whitespace: true,
          message: _this.$t('message.common.inputPlaceholder', { field: _this.$t(`message.checkout.${ param }`).toLowerCase() })
        }
      ];
    });
    _this.formRules.childCode = [
      {
        required: true,
        whitespace: true,
        message: _this.$t('message.common.selectPlaceholder', { field: _this.$t(getSubSelectMethodBackName(selectMethod)).toLowerCase() })
      }
    ];
  }
};

// 获取子选择框的 label
export const getSubSelectMethodBackName = (selectMethod) => {
  if (selectMethod) {
    switch (selectMethod.code) {
      case 'MOBILEMONEY':
        return 'message.checkout.mobileMoneyOperator';
      case 'USSD':
        return 'message.checkout.bank';
      default:
        return '';
    }
  }
  return '';
};


