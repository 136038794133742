<template>
  <a-spin :spinning="spinning">
    <template #indicator>
      <Loading></Loading>
    </template>
    <div>
      <div class="payments" v-if="orderDetails&&!refreshPage">
        <PcCheckout ref="pcCheckout" class="pc-checkout" :order="orderDetails"></PcCheckout>
        <MobileCheckout ref="mobileCheckout" class="mobile-checkout" :order="orderDetails"></MobileCheckout>
      </div>
    </div>
  </a-spin>
</template>
<script>
import { getOrderInfo } from '@/views/checkout/api';
import PcCheckout from '@/views/checkout/payment/components/pc.vue';
import MobileCheckout from '@/views/checkout/payment/components/mobile.vue';
import Loading from '@/components/loading';

export default {
  components: { PcCheckout, MobileCheckout, Loading },
  data() {
    return {
      orderDetails: null,
      orderId: this.$route.params.id,
      spinning: false,
      refreshPage: false,
      countdown: 0
    };
  },
  async created() {
    await this.initOrderInfo();
  },
  methods: {
    setAllCountdown() {
      if (this.$refs.pcCheckout) {
        this.$refs.pcCheckout.setCountdown(this.countdown);
      }
      if (this.$refs.mobileCheckout) {
        this.$refs.mobileCheckout.setCountdown(this.countdown);
      }
    },
    async initOrderInfo() {
      try {
        this.spinning = true;
        const res = await getOrderInfo(this.orderId);
        if (res.status !== 200) {
          this.$router.push({
            name: 'CheckoutFailed',
            params: { id: this.orderId }
          });
          return;
        }
        this.orderDetails = res;
        if (this.orderDetails.supportCreditCard) {
          this.orderDetails.paymentLogos.unshift({
            cardName: 'CARD',
            logo: require('../../../assets/images/checkout/card.png'),
            supportCountry: ['ALL'],
            requiredFields: this.orderDetails.requiredFields
          });
        }
        this.countdown = this.orderDetails.expires;
        this.$nextTick(() => {
          this.setAllCountdown();
        });
        const timer = setInterval(() => {
          if (this.countdown > 0) {
            this.countdown -= 1;
            this.setAllCountdown();
          } else {
            clearInterval(timer);
          }
        }, 1000);
        this.spinning = false;
      } catch (error) {
        this.$router.push({
          name: 'CheckoutFailed',
          params: { id: this.orderId }
        });
        this.spinning = false;
      }
    },
    onLanguageChange() {
      this.refreshPage = true;
      this.$nextTick(() => {
        this.refreshPage = false;
        this.$nextTick(() => {
          this.setAllCountdown();
        });
      });
    }
  },
  watch: {
    '$store.getters.app.currentLan': 'onLanguageChange'
  }
};
</script>
<style lang="less" scoped>
@import '~@/styles/common.less';

.payments {
  .mobile-checkout {
    display: none;
  }
}

@media screen and(max-width: 900px) {
  .payments {
    .pc-checkout {
      display: none;
    }

    .mobile-checkout {
      display: block;
    }
  }
}
</style>
