<template>
  <a-spin :spinning="spinning">
    <template #indicator>
      <Loading></Loading>
    </template>
    <div class="mobile">
      <Lang></Lang>
      <div class="title">{{ $t('message.checkout.title') }}</div>
      <div class="description">{{ $t('message.checkout.description') }}</div>
      <a-button v-if="orderDetails.paymentLogos.length > 1" @click="viewAllMethod" class="more-methods" type="link">
        {{ $t('message.checkout.otherPaymentMethods') }}
      </a-button>
      <div class="form-wrapper">
        <div class="countdown-wrapper">
          <div class="cards" v-if="orderDetails.paymentLogos[choosedPaymentMethodIndex].cardName === 'CARD'">
            <img v-for="(card, cardIndex) in orderDetails.creditCardLogos" :key="cardIndex"
                 :src="`data:image/png;base64,${card.logo}`"/>
          </div>
          <div class="local" v-else>
            <img :src="`data:image/png;base64,${orderDetails.paymentLogos[choosedPaymentMethodIndex].logo}`"/>
            <span>{{ orderDetails.paymentLogos[choosedPaymentMethodIndex].cardName }}</span>
          </div>
          <div class="countdown"><span class="time">{{ Math.floor(countdown / 60) }}</span> : <span
            class="time">{{ countdown % 60 }}</span></div>
        </div>
        <div class="order-info">
          <div class="order-info-content">
            <div class="item">
              <span class="label">{{ $t('message.checkout.orderAmount') }}</span>
              <span class="text amount">{{ orderDetails.amount }} {{ orderDetails.currency }}</span>
            </div>
            <div class="item">
              <span class="label">{{ $t('message.checkout.orderId') }}</span>
              <span class="text">{{ orderDetails.id }}</span>
            </div>
            <div class="item">
              <span class="label">{{ $t('message.checkout.billingDescriptor') }}</span>
              <span class="text">{{ orderDetails.billingDescription }}</span>
            </div>
          </div>
        </div>
        <a-form-model :model="form" :colon="false" :rules="formRules" ref="formRef">
          <div class="payment-info" v-if="orderDetails.paymentLogos[choosedPaymentMethodIndex].cardName === 'CARD'">
            <a-form-model-item prop="cardNumber">
              <template #label>
                <div class="card-label">
                  <span>{{ $t('message.checkout.cardNumber') }}</span>
                  <div class="card-logos">
                    <img
                      class="card-logo"
                      v-for="(card, cardIndex) in orderDetails.creditCardLogos"
                      :key="cardIndex"
                      :src="`data:image/png;base64,${card.logo}`"/>
                  </div>
                </div>
              </template>
              <cardnumber :allow-clear="allowClear" :cards="orderDetails.creditCardLogos"
                          :value.sync="form.cardNumber"></cardnumber>
            </a-form-model-item>
            <a-row :gutter="9">
              <a-col :span="12">
                <a-form-model-item :label="$t('message.checkout.cardName')" prop="cardFirstName">
                  <a-input v-model="form.cardFirstName" :size="size" :allow-clear="allowClear"/>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item :label="' '" prop="cardLastName" class="no-required">
                  <a-input v-model="form.cardLastName" :size="size" :allow-clear="allowClear"/>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-form-model-item :label="$t('message.checkout.cvv')" prop="cvv">
              <a-input v-model="form.cvv" :size="size" :allow-clear="allowClear" inputmode="numeric" :maxLength="4"
                       @input.native="cvvChangeHandler">
                <template #suffix>
                  <img class="cvv-img" src="../../../../assets/images/checkout/cvv.png"/>
                </template>
              </a-input>
            </a-form-model-item>
            <a-form-model-item :label="$t('message.checkout.expiredDate')" prop="expiredDate">
              <a-input v-model="form.expiredDate" :size="size"
                       :maxLength="5"
                       :allow-clear="allowClear"
                       placeholder="MM/YY"
                       autocomplete="off"
                       @input.native="expiredDateChangeHandler">
                <template #suffix>
                  <img class="expired-date-img" src="../../../../assets/images/checkout/expired.png"/>
                </template>
              </a-input>
            </a-form-model-item>
          </div>
          <!--     bank 子项选择     -->
          <div class="payment-info" v-if="bankList&&bankList.length>0">
            <a-form-model-item :label="$t(getSubSelectMethodBackName(canSelectMethods[choosedPaymentMethodIndex]))"
                               prop="childCode">
              <a-select v-model="form.childCode" :size="size" :showSearch="true" :optionFilterProp="'label'">
                <a-select-option v-for="(country, countryIndex) in bankList"
                                 :key="countryIndex"
                                 :value="country.code"
                                 :label="country.name">
                  {{ country.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </div>
          <div class="billing-info">
            <div class="billing-title">{{ $t('message.checkout.billingInfo') }}</div>
            <a-row :gutter="9">
              <a-col :span="12">
                <a-form-model-item :label="$t('message.checkout.country')" prop="country">
                  <a-select v-model="form.country" :size="size" :showSearch="true" :optionFilterProp="'label'"
                            @change="onCountryChange">
                    <a-select-option
                      v-for="(country, countryIndex) in countryList"
                      :key="countryIndex"
                      :value="country.code"
                      :label="country.name">
                      {{ country.name }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item :label="$t('message.checkout.city')" prop="city">
                  <a-input v-model="form.city" :size="size"/>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-form-model-item :label="$t('message.checkout.state')" prop="state">
              <a-input v-model="form.state" :size="size"/>
            </a-form-model-item>
            <a-row :gutter="9">
              <a-col :span="displayItems.streetNumber?12:24">
                <a-form-model-item :label="$t('message.checkout.address')" prop="address">
                  <a-input v-model="form.address" :size="size"/>
                </a-form-model-item>
              </a-col>
              <a-col :span="12" v-if="displayItems.streetNumber">
                <a-form-model-item :label="$t('message.checkout.streetNumber')" prop="streetNumber">
                  <a-input v-model="form.streetNumber" :size="size"/>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row :gutter="9">
              <a-col :span="12">
                <a-form-model-item :label="$t('message.checkout.firstName')" prop="firstName">
                  <a-input v-model="form.firstName" :size="size"/>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item :label="$t('message.checkout.lastName')" prop="lastName">
                  <a-input v-model="form.lastName" :size="size"/>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-form-model-item v-if="displayItems.document" :label="documentLabel||$t('message.checkout.document')"
                               prop="document">
              <a-input v-model="form.document" :size="size"/>
            </a-form-model-item>
            <a-form-model-item :label="$t('message.checkout.zipCode')" prop="zipCode">
              <a-input v-model="form.zipCode" :size="size"/>
            </a-form-model-item>
            <a-form-model-item :label="$t('message.checkout.email')" prop="email">
              <a-input v-model="form.email" :size="size"/>
            </a-form-model-item>
            <a-form-model-item :label="$t('message.checkout.phone')" prop="phone">
              <a-input v-model="form.phone" :size="size"/>
            </a-form-model-item>
          </div>
        </a-form-model>
      </div>
      <div class="btns">
        <a-button class="button" type="primary" :size="size" @click="submit">
          <a-icon type="lock" theme="filled"/>
          <span>{{ orderDetails.amount }} {{ $t('message.checkout.pay') }}</span>
        </a-button>
        <a-button @click="cancel" class="return-link" type="link">{{
            $t('message.checkout.returnToMerchant')
          }}
        </a-button>
      </div>
      <a-drawer
        placement="bottom"
        :closable="false"
        :visible="viewMorePaymentMethod"
        :keyboard="false"
        :maskClosable="false"
      >
        <template #title>
          <a-button @click="handleCancel" class="close" type="link">{{ $t('message.checkout.close') }}</a-button>
        </template>
        <div class="items">
          <div class="item"
               v-for="(method, methodIndex) in canSelectMethods"
               :key="methodIndex"
               @click="chooseMethod(methodIndex,method)"
               :class="{active: activePaymentMethodIndex === methodIndex}">
            <img v-if="method.cardName === 'CARD'" class="method-img" :src="method.logo"/>
            <img v-else class="method-img" :src="`data:image/png;base64,${method.logo}`"/>
            <span v-if="method.cardName === 'CARD'" class="method-name">Credit/Debit Card</span>
            <span v-else class="method-name">{{ method.cardName }}</span>
          </div>
        </div>
      </a-drawer>
    </div>
  </a-spin>
</template>
<script>
import cardnumber from '@/components/cardnumber';
import Loading from '@/components/loading';
import countryList from '@/utils/tools/country';
import { getCheckoutData, rules, initFormData, onExpiredDateInput, onSecurityCodeInput } from '@/utils/tools/checkout';
import { confirm } from '@/views/checkout/api';
import Lang from '@/components/lang/index.vue';
import {
  getSubSelectMethodBackName,
  initBankList,
  initCanUseMethod,
  initRequiredFields,
  showDocument
} from '@/views/checkout/tools';

export default {
  components: { cardnumber, Loading, Lang },
  props: {
    order: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      countryList,
      orderDetails: this.$props.order,
      canSelectMethods: [],
      size: 'large',
      documentLabel: '',
      allowClear: true,
      viewMorePaymentMethod: false,
      choosedPaymentMethod: null,
      choosedPaymentMethodIndex: 0,
      activePaymentMethodIndex: 0,
      spinning: false,
      loading: false,
      form: {
        cardNumber: '',
        cardFirstName: '',
        cardLastName: '',
        cvv: '',
        expiredDate: '',
        country: '',
        childCode: '',
        city: '',
        state: '',
        firstName: '',
        lastName: '',
        document: '',
        address: '',
        streetNumber: '',
        zipCode: '',
        email: '',
        phone: ''
      },
      formSaveRules: {
        cardNumber: [
          {
            required: true,
            whitespace: true,
            message: this.$t('message.common.inputPlaceholder', { field: this.$t('message.checkout.cardNumber').toLowerCase() })
          },
          { validator: rules.cardNumber, trigger: 'blur' }
        ],
        expiredDate: [
          {
            required: true,
            whitespace: true,
            message: this.$t('message.common.inputPlaceholder', { field: this.$t('message.checkout.expiredDate').toLowerCase() })
          },
          { validator: rules.monthYear, trigger: 'blur' }
        ],
        cvv: [
          {
            required: true,
            whitespace: true,
            message: this.$t('message.common.inputPlaceholder', { field: this.$t('message.checkout.cvv').toLowerCase() })
          },
          { validator: rules.cvv, trigger: 'blur' }
        ],
        cardFirstName: [
          {
            required: true,
            whitespace: true,
            message: this.$t('message.common.inputPlaceholder', { field: this.$t('message.checkout.cardName').toLowerCase() })
          }
        ],
        cardLastName: [
          {
            required: true,
            whitespace: true,
            message: this.$t('message.common.inputPlaceholder', { field: this.$t('message.checkout.cardName').toLowerCase() })
          }
        ],
      },
      formRules: {},
      countdown: 0,
      orderId: this.$route.params.id,
      displayItems: {},
      // 本地支付银行列表
      bankList: []
    };
  },
  async created() {
    try {
      this.onCountryChange(this.orderDetails?.billing?.country);
      initRequiredFields(this, this.orderDetails?.paymentLogos[0]);
      initFormData(this.form, this.orderDetails);
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    getSubSelectMethodBackName,
    onCountryChange(country) {
      this.documentLabel = showDocument(country);
      this.canSelectMethods = initCanUseMethod(this.orderDetails?.paymentLogos, country);
    },
    expiredDateChangeHandler(evt) {
      this.form.expiredDate = onExpiredDateInput(evt);
    },
    setCountdown(countdown) {
      this.countdown = countdown;
    },
    cvvChangeHandler(evt) {
      this.form.cvv = onSecurityCodeInput(evt);
    },
    submit() {
      this.$refs.formRef.validate(async valid => {
        if (valid) {
          this.spinning = true;
          const submitData = getCheckoutData(this.form, this.orderDetails.paymentLogos[this.choosedPaymentMethodIndex]);
          try {
            const res = await confirm(this.orderId, submitData);
            if (res.status === 301) {
              window.location.href = res.redirectUrl;
              return;
            }
            let name = '';
            if (res.status === 100) {
              name = 'CheckoutSuccess';
            } else if (res.status === 102) {
              name = 'CheckoutPending';
            } else {
              name = 'CheckoutFailed';
            }
            this.$router.push({
              name,
              params: { id: this.orderId }
            });
            this.spinning = false;
          } catch (error) {
            this.spinning = false;
          }
        }
      });
    },
    cancel() {
      window.location.href = this.orderDetails.cancelUrl;
    },
    viewAllMethod() {
      this.activePaymentMethodIndex = this.choosedPaymentMethodIndex;
      this.viewMorePaymentMethod = true;
    },
    chooseMethod(index, methodItem) {
      this.choosedPaymentMethodIndex = index;
      this.activePaymentMethodIndex = index;
      this.viewMorePaymentMethod = false;
      this.form.childCode = '';
      initRequiredFields(this, methodItem);
      this.bankList = initBankList(methodItem, this.form.country);
    },
    handleCancel(e) {
      this.viewMorePaymentMethod = false;
    }
  }
};
</script>
<style lang="less" scoped>
@import '~@/styles/common.less';

.mobile {
  padding: 15px 25px 0;
  background-color: @checkout-bg;

  //.title-lang {
  //  display: flex;
  //  justify-content: space-between;
  //  align-items: center;
  //  height: 40px;
  //}

  .title {
    #Font.heavy();
    font-size: 22px;
    line-height: 40px;
  }

  .description {
    #Font.regular();
    font-size: 12px;
    line-height: 18px;
  }

  .more-methods {
    padding: 0;
    #Font.heavy();
    font-size: 14px;
    margin-top: 10px;
    height: 19px;
  }

  .form-wrapper {
    background-color: @white;
    border-radius: 6px 0px 0px 6px;
    margin-top: 25px;

    .countdown-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 15px;
      border-bottom: 1px solid @checkout-bg;

      .cards {
        img {
          height: 20px;
        }
      }

      .local {
        display: flex;
        align-items: center;
        gap: 5px;

        img {
          height: 20px;
        }
      }

      .countdown {
        .time {
          #Font.heavy();
          display: inline-block;
          text-align: center;
          font-size: 14px;
          background: @checkout-bg;
          border-radius: 2px;
          width: 28px;
          height: 23.5px;
          line-height: 23.5px;
        }
      }
    }

    .order-info {
      padding: 13.5px 15px 20px;

      .order-info-content {
        background-color: @checkout-bg;
        border-radius: 5px;
        padding: 13px 10px;

        .item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          line-height: 16.5px;
          font-size: 12px;
          margin-top: 9px;

          &:first-child {
            margin-top: 0px;
          }

          .label {
            #Font.regular();
          }

          .text {
            #Font.heavy();

            &.amount {
              font-size: 15px;
              line-height: 20.5px;
            }
          }
        }
      }
    }

    .payment-info {
      background-color: @white;
      padding: 13.5px 15px 0;

      .cvv-img {
        width: 38px;
      }

      .expired-date-img {
        width: 38px;
      }

      .card-label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .card-logos {
          display: flex;
          gap: 5px;

          .card-logo {
            height: 20px;
          }
        }
      }
    }

    .billing-info {
      padding: 0 15px 5px;
      color: @checkout-text-color;

      .billing-title {
        #Font.heavy();
        font-size: 14px;
        line-height: 19px;
        padding-bottom: 15px;
      }
    }
  }

  .btns {
    padding: 16px 0px 160px;
    text-align: center;

    .button {
      width: 100%;
      #Font.heavy();
      font-size: 16px;
    }

    .return-link {
      padding: 0;
      #Font.heavy();
      font-size: 16px;
      margin-top: 20px;
    }
  }
}

.ant-drawer {
  ::v-deep(.ant-drawer-header) {
    border-bottom: 0;
    position: fixed;
    width: 100%;

    .ant-drawer-title {
      display: flex;
      justify-content: flex-end;

      .close {
        padding: 0;
        #Font.heavy();
        font-size: 14px;
        line-height: 18px;
        height: 18px;
      }
    }
  }

  ::v-deep(.ant-drawer-body) {
    padding-top: 74px;
  }

  .items {
    .item {
      border-radius: 4px;
      height: 65px;
      display: flex;
      margin-top: 7px;
      display: flex;
      align-items: center;
      padding: 0 12px;
      cursor: default;

      &:first-child {
        margin-top: 0;
      }

      &.active {
        background: #f1f5f7;
      }

      .method-img {
        height: 33.5px;
        margin-right: 28px;
      }

      .method-name {
        #Font.heavy();
        font-size: 18px;
        line-height: 25px;
        color: @checkout-text-color;
      }
    }
  }
}
</style>
