import http from '@/utils/http/index'

// 订单信息
export function getOrderInfo(id) {
  return http.get(`/checkout/information/${id}`);
}

// 确认支付
export function confirm(id, data) {
  return http.post(`/checkout/confirm/${id}`, data);
}

// 订单状态
export function getOrderStatus(id) {
  return http.get(`/checkout/result/${id}`);
}
